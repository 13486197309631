var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"oomPage"}},[_c('div',{staticClass:"tRowTopMain",class:{tRowTopMainnonMobile : _vm.isMobile(true) }},[_c('MiniMenu',{attrs:{"config":_vm.config,"seasonCode":_vm.id,"tour":_vm.tour}})],1),(_vm.isMobile(true))?[_c('div',{staticClass:"titleLine"},[_c('b-container',{staticClass:"topSec"},[[_c('div',{staticClass:"titleBackGround"},[_c('b-row',{staticClass:"topSecrow"},[_c('b-col',{attrs:{"md":"6","cols":"12"}},[_c('h2',{staticClass:"fullName"},[_vm._v(" "+_vm._s(_vm.data.full_name)+" ")])])],1)],1),_c('b-dropdown',{attrs:{"text":this.id + ' Season'}},_vm._l((_vm.tm_params.seasons.seasons_entry.slice().reverse()),function(seasons,index){return _c('b-dropdown-item',{key:index,attrs:{"title":seasons.code,"year":seasons.code},on:{"click":_vm.changeYear}},[_vm._v(_vm._s(seasons.desc))])}),1),(
              this.tourom === '99' ||
                this.tourom === '98' ||
                this.tourom === '0' ||
                this.tourom === 'T1'
            )?_c('div'):_c('div',[(_vm.stats.ooms === '')?_c('b-dropdown',{attrs:{"text":'Report'}},[_c('b-dropdown-item',[_vm._v("No Stats")])],1):(Array.isArray(_vm.stats.ooms.ooms_entry))?_c('b-dropdown',{attrs:{"text":_vm.data.short_name}},_vm._l((_vm.stats.ooms.ooms_entry),function(ooms,index){return _c('b-dropdown-item',{key:index + Math.random(),attrs:{"title":'ooms-oom-' + ooms.code},on:{"click":_vm.pickStat}},[_vm._v(_vm._s(ooms.short_name))])}),1):_c('b-dropdown',{attrs:{"text":_vm.data.short_name}},[_c('b-dropdown-item',{attrs:{"title":'ooms-oom-' + _vm.stats.ooms.ooms_entry.code},on:{"click":_vm.pickStat}},[_vm._v(_vm._s(_vm.stats.ooms.ooms_entry.full_name))])],1)],1)]],2)],1)]:[_c('div',{staticClass:"titleLine"},[_c('b-container',{staticClass:"topSec"},[[_c('b-row',[_c('b-col',[_c('div',{staticClass:"titleBackGround"},[_c('b-row',{staticClass:"topSecrow"},[_c('b-col',{attrs:{"md":"6","cols":"12"}},[_c('h2',{staticClass:"fullName"},[_vm._v(" "+_vm._s(_vm.data.full_name)+" ")])]),_c('b-col',{attrs:{"md":"6","cols":"12"}},[_c('b-nav',{attrs:{"pills":""}},[_c('b-dropdown',{attrs:{"text":this.id + ' Season'}},_vm._l((_vm.tm_params.seasons.seasons_entry.slice().reverse()),function(seasons,index){return _c('b-dropdown-item',{key:index,attrs:{"title":seasons.code,"year":seasons.code},on:{"click":_vm.changeYear}},[_vm._v(_vm._s(seasons.desc))])}),1),(
                          this.tourom === '99' ||
                            this.tourom === '98' ||
                            this.tourom === '0' ||
                            this.tourom === 'T1'
                        )?_c('div'):_c('div',[(_vm.stats.ooms === '')?_c('b-dropdown',{attrs:{"text":'Report'}},[_c('b-dropdown-item',[_vm._v("No Stats")])],1):(Array.isArray(_vm.stats.ooms.ooms_entry))?_c('b-dropdown',{attrs:{"text":_vm.data.short_name}},_vm._l((_vm.stats.ooms.ooms_entry),function(ooms,index){return _c('b-dropdown-item',{key:index + Math.random(),attrs:{"title":'ooms-oom-' + ooms.code},on:{"click":_vm.pickStat}},[_vm._v(_vm._s(ooms.short_name))])}),1):_c('b-dropdown',{attrs:{"text":_vm.data.short_name}},[_c('b-dropdown-item',{attrs:{"title":'ooms-oom-' + _vm.stats.ooms.ooms_entry.code},on:{"click":_vm.pickStat}},[_vm._v(_vm._s(_vm.stats.ooms.ooms_entry.full_name))])],1)],1)],1)],1)],1)],1)])],1)]],2)],1)],_c('b-container',[_c('b-row',[_c('b-col',[(_vm.stats.ooms === '')?_c('h3',{staticClass:"noStats"},[_vm._v(" No Stats For This Season ")]):_c('StatsTable',{attrs:{"id":_vm.id,"stat":_vm.stat,"data":_vm.data,"config":_vm.config}})],1)],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }