<template>
  <div>
    <div class="splash">
      <div class="titleCont">
        <h1>
          PGA Professionals News
        </h1>
      </div>
    </div>
    <div class='mainContent'>
    <b-container>
      <div class="overflow-auto">
        <b-row>
          <b-col
            v-for="(featpost, index) in items.slice(firstSplit, secondSplit)"
            :key="index"
            lg='4' md='12'
            class="noRightPad bottomPad col-md-4"
          >
            <div class="box"> 
              <a :href="'/news/' + featpost.id">
                <b-card
                  title="PGA Professionals"
                  :sub-title="featpost.title.rendered"
                  :img-src="featpost.better_featured_image.source_url"
                  img-alt="Image"
                  img-top
                  tag="article"
                >
                </b-card>
              </a>
            </div>
          </b-col>
        </b-row>
        <b-pagination-nav
          :link-gen="linkGen"
          :number-of-pages="rows"
          use-router
        ></b-pagination-nav>
      </div>
    </b-container>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "PGAPRO",
  data() {
    return {
      items: [],
    };
  },
  methods: {
    linkGen(pageNum) {
      return pageNum === 1 ? "?" : `?page=${pageNum}`;
    },
  },
  computed: {
    rows() {
      return Math.round(this.items.length / 6);
    },
    firstSplit() {
      if (isNaN(this.$route.query.page - 1) * 6) return 0;
      else return (this.$route.query.page - 1) * 6;
    },
    secondSplit() {
      if (isNaN(this.$route.query.page - 1) * 6) return 6;
      else return this.$route.query.page * 6;
    },
  },
  mounted() {
    axios //add API Call
      .get(process.env.VUE_APP_WPAPI_URL+"wp/v2/posts?categories=44&per_page=99")
      .then((response) => (this.items = response.data));
  },
};
</script>

<style scoped>
.titleCont {
  position: relative;
  top: 49%;
  transform: translateY(-50%);
  text-align: center;
}
.splash {
  background-image: url("https://assets.pga.org.au/pga-aus/2019/08/PGA-Website-HeaderBanner-About-1920x550px.jpg");
  text-align: center;
  color: white;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  max-height: 550px;
  height: 275px;
  margin-top: -20px;
}
.img-fluid {
  max-width: 100%;
  height: auto;
  width: 100%;
}
.card {
  height: 335px;
  padding-bottom: 5px;
}
.card.feat {
  height: 621px;
}
span.postText {
  font-size: 12pt;
}
h1.postsTitle {
  font-size: 20pt;
  padding-top: 15px;
  padding-bottom: 15px;
  white-space: nowrap;
  width: 350px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.overflowImage {
  overflow: hidden;
  height: 278px;
}

::v-deep .nav-link {
  color: white !important;
  background-color: #263056;
  width: 100%;
  text-align: center;
}
.card-title {
  font-weight: 700;
  letter-spacing: -0.5px;
  color: #9aa5ac;
  margin-top: -2px;
  margin-bottom: -5px;
  font-size: 8pt;
  color: #4e7650;
}
.feat > .card-body > .card-subtitle {
  font-size: 23pt !important;
}
.card-subtitle {
  color: #01285e !important;
  font-weight: 700;
  font-size: 12pt;
  background-color: #fff;
  margin-top: 15px; 
}
.viewAll {
  position: relative;
  top: 1em;
  color: #000;
  font-weight: 600 !important;
  font-size: 14px;
  font-family: 'Gantari', sans-serif!important;
}
.noRightPad {
  padding-right: 0;
  padding-left: 5px;
}
.bottomPad {
  padding-bottom: 20px;
}
.mainContent{
  background-color: #f9f9f9;
  padding: 100px 0;
}
.box {
  display: block;
  top: 0px;
  border-radius: 4px;
  position: relative;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
}

.box:hover {
  transition: all 0.3s ease;
  box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
  top: -4px;
  background-color: white;
    color: #00b588;
}
@media only screen and (max-width: 768px) {
  .card{
    height: 350px;
  }
}
</style>
