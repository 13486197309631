<template>
  <table>
    <thead v-bind:style="{ 'background-color':  reportColour}">
      <tr>
        <th>
          Pos
        </th>
        <th >
          Country
        </th>
        <th>
          Player Name
        </th>
        <th >
          Earnings (AUD)
        </th>
        <th >
          Played
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(stats, index) in data.oom.oom_entry" :key="index">
        <td>
          {{stats.pos}}
        </td>
        <td>
          <span>
            <img
              :class="flag"
              :src="
                (config.VUE_APP_FLAG_URL +
                  stats.nationality)
                  | lowercase
              "
            />
          </span>
        </td>
        <td>
          <a :href="'/playerprofile/' + stats.playerno" class="namelink">
            {{ stats.forename }} {{ stats.surname }}
          </a>
        </td>
        <td>
          {{ stats.oom_value}}
        </td>
        <td>
          {{ stats.tournaments_played }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "statsTable",
  props: ["id", "stat", "data", 'config'],
  data() {
    return {
      noData: "noData",
      flag: "flag",
      cell: "cell",
      fields: "fields",
      name: "name",
      reportColour: process.env.VUE_APP_REPORT_MAIN_COLOUR
    };
  },
  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }
      return value.toLowerCase() + ".svg";
    }
  }
};
</script>

<style scoped>
.namelink {
  color: #000!important;
}
.namelink:hover {
  color: #00b588!important;
  text-decoration: none;
}
table > tbody > tr > td {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 0px solid #ddd;
  border-right: none !important;
  padding: 0.8em 0.5em 0.8em 1em !important;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  color: #000;
  text-align: center;
}
 table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 0px solid #ddd;
  font-weight: bold;
  color: white;
  font-size: 13px;
  text-transform: capitalize;
  position: sticky;
  position: -webkit-sticky;
  top: -1px;
  /* background: #00433e; */
  color: #ffffff !important;
  text-align: center;
}
table {
  width: 100%;
}
.flag {
  width: 30px;
  box-shadow: 0 0 3px #555;
}

 tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.04);
  /* background-color: #dfe1e6; */
}
</style>
