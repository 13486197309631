<template>
  <div id="education">
    <div
      v-if="this.$route.query.wppage !== '21058'"
      class="splash"
      v-bind:style="{
        backgroundImage: 'url(' + 'https://assets.pga.org.au/pga-aus/2022/01/PGA-Website-HeaderBanner-AllPages-1920x550px-copy.jpeg' + ')',
      }"
    >
      <div class="titleCont">
        <h1>
          <span v-html="wpedu[0].title.rendered"></span>
        </h1>
      </div>
    </div>
    <b-container class="mainContent">
      <span v-html="wpedu[0].content.rendered"></span>
      <b-row>
        <b-col
          class="col-md-6 pad"
          v-for="(edu, index) in eduLinks.slice().reverse()"
          :key="index"
        >
          <div class="box">
            <b-card
              :title="edu.acf.title"
              :img-src="edu.acf.image"
              img-alt="Image"
              img-top
              tag="article"
              class="TESTTT"
            >
              <b-nav-item
                v-if="edu.slug === 'bridging-program'"
                :to="'/education/' + edu.slug"
              >
                <span v-html="edu.acf.paragraph"></span>
                <b-button
                  class="LearnMorebtn"
                  variant="primary"
                  v-bind:href="edu.acf.learn_more_button_link"
                  >Learn More</b-button
                >
              </b-nav-item>
              <b-nav-item
                v-if="
                  edu.slug ===
                    'pga-membership-pathway-program-formerly-pga-trainee-program'
                "
                :to="'/education/' + edu.slug"
              >
                <span v-html="edu.acf.paragraph"></span>
                <b-button
                  class="LearnMorebtn"
                  variant="primary"
                  v-bind:href="edu.acf.learn_more_button_link"
                  >Learn More</b-button
                >
              </b-nav-item>
              <b-nav-item
                v-if="edu.slug === '2019-pga-golf-expo'"
                :to="'/education/' + edu.slug"
              >
                <span v-html="edu.acf.paragraph"></span>
                <b-button
                  class="LearnMorebtn"
                  variant="primary"
                  v-bind:href="edu.acf.learn_more_button_link"
                  >Learn More</b-button
                >
              </b-nav-item>
              <b-nav-item
                v-if="edu.slug === 'pga-international-golf-institute'"
                :to="'/education/' + edu.slug"
              >
                <span v-html="edu.acf.paragraph"></span>
                <b-button
                  class="LearnMorebtn"
                  variant="primary"
                  v-bind:href="edu.acf.learn_more_button_link"
                  >Learn More</b-button
                >
              </b-nav-item>
              <b-nav-item
                v-if="edu.slug === 'pga-centre-for-learning-and-performance'"
                :to="'/education/' + edu.slug"
              >
                <span v-html="edu.acf.paragraph"></span>
                <b-button
                  class="LearnMorebtn"
                  variant="primary"
                  v-bind:href="edu.acf.learn_more_button_link"
                  >Learn More</b-button
                >
              </b-nav-item>
              <b-nav-item
                v-if="edu.slug === 'community-instructor-program'"
                :to="'/education/' + edu.slug"
              >
                <span v-html="edu.acf.paragraph"></span>
                <b-button
                  class="LearnMorebtn"
                  variant="primary"
                  v-bind:href="edu.acf.learn_more_button_link"
                  >Learn More</b-button
                >
              </b-nav-item>
              <b-nav-item
                v-if="edu.slug === 'pga-academy'"
                :to="'/education/' + edu.slug"
              >
                <span v-html="edu.acf.paragraph"></span>
                <b-button
                  class="LearnMorebtn"
                  variant="primary"
                  v-bind:href="edu.acf.learn_more_button_link"
                  >Learn More</b-button
                >
              </b-nav-item>
              <b-nav-item
                v-if="edu.slug === 'juniors'"
                :to="'/play/' + edu.slug"
              >
                <span v-html="edu.acf.paragraph"></span>
                <b-button
                  class="LearnMorebtn"
                  variant="primary"
                  v-bind:href="edu.acf.learn_more_button_link"
                  >Learn More</b-button
                >
              </b-nav-item>
              <b-nav-item
                v-if="edu.slug === 'where-can-i-play'"
                href="https://www.golf.org.au/find-a-golf-club"
                target="_blank"
              >
                <span v-html="edu.acf.paragraph"></span>
                <b-button
                  class="LearnMorebtn"
                  variant="primary"
                  v-bind:href="edu.acf.learn_more_button_link"
                  >Learn More</b-button
                >
              </b-nav-item>
              <b-nav-item
                v-if="edu.slug === 'womens-golf-network'"
                :to="'/play/' + edu.slug"
              >
                <span v-html="edu.acf.paragraph"></span>
                <b-button
                  class="LearnMorebtn"
                  variant="primary"
                  v-bind:href="edu.acf.learn_more_button_link"
                  >Learn More</b-button
                >
              </b-nav-item>
              <b-nav-item
                v-if="edu.slug === 'volkswagen-scramble'"
                :to="'/play/' + edu.slug"
              >
                <span v-html="edu.acf.paragraph"></span>
                <b-button
                  class="LearnMorebtn"
                  variant="primary"
                  v-bind:href="edu.acf.learn_more_button_link"
                  >Learn More</b-button
                >
              </b-nav-item>
              <b-nav-item
                v-if="edu.slug === 'all-abilities-coaches'"
                :to="'/play/' + edu.slug"
              >
                <span v-html="edu.acf.paragraph"></span>
                <b-button
                  class="LearnMorebtn"
                  variant="primary"
                  v-bind:href="edu.acf.learn_more_button_link"
                  >Learn More</b-button
                >
              </b-nav-item>
              <b-nav-item
                v-if="edu.slug === 'first-tee-australia'"
                :to="'/play/' + edu.slug"
              >
                <span v-html="edu.acf.paragraph"></span>
                <b-button
                  class="LearnMorebtn"
                  variant="primary"
                  v-bind:href="edu.acf.learn_more_button_link"
                  >Learn More</b-button
                >
              </b-nav-item>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "education",
  props: ["slug"],
  data() {
    return {
      wpedu: [],
      eduLinks: [],
    };
  },
  computed: {
    routeSlug: function(route) {
      if (this.slug === undefined) {
        route = this.$route.name;
      } else {
        route = this.slug;
      }
      return route;
    },
  },
  mounted() {
    axios
      .get(process.env.VUE_APP_WPAPI_URL + "wp/v2/pages?slug=" + this.routeSlug)
      .then((response) => {
        this.wpedu = response.data;
        return axios.get(
          process.env.VUE_APP_WPAPI_URL + "wp/v2/" + this.routeSlug + "/"
        );
      })
      .then((response) => {
        this.eduLinks = response.data;
      });
  },
};
</script>

<style scoped>
::v-deep .wgn-buttons a {
  color: #fff !important;
  height: 48px;
  font-size: 12pt;
}
::v-deep td.has-text-align-center {
  text-align: center;
}
::v-deep tr:first-child {
  border-bottom: 1px solid;
}
::v-deep img.wp-image-21894 {
  width: 120px !important;
}
::v-deep img.wp-image-21897 {
  width: 120px !important;
}
::v-deep td {
  font-size: 16px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 25px;
  padding-bottom: 25px;
}
::v-deep figure.wp-block-table.wig-table {
  background: #fff;
  padding: 16px;
  border: 1px solid;
}
::v-deep h2.has-text-align-center {
  text-align: center;
  margin-top: 30px;
}
::v-deep a.buttonLink:hover {
  background-color: #00b588 !important;
  border-color: #00b588;
  text-decoration: none !important;
}
::v-deep a.buttonLink {
  background-color: #003629;
  color: #fff !important;
  /* padding: 12px !important; */
  padding: 7px 20px !important;
  margin: 15px 0;
  display: inline-block !important;
  line-height: 3 !important;
  height: 70px !important;
}
a.btn.LearnMorebtn.btn-primary {
  color: #fff !important;
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #01285e;
  border-color: #01285e;
}
a.btn.LearnMorebtn.btn-primary:hover {
  color: #fff !important;
  position: relative;
  padding-bottom: 10px;
  padding-top: 10px;
  background-color: #007bff;
  border-color: #007bff;
}
.education {
  background-color: #f9f9f9;
}
.titleCont {
  position: relative;
  top: 49%;
  transform: translateY(-50%);
  text-align: center;
}
.splash {
  text-align: center;
  color: white;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  max-height: 550px;
  height: 275px;
  margin-top: -20px;
}
.mainContent {
  padding: 100px 0;
}
.box {
  display: block;
  top: 0px;
  border-radius: 4px;
  position: relative;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
}

.box:hover {
  transition: all 0.3s ease;
  box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
  top: -4px;
  background-color: white;
  color: #00b588;
}
.card-title {
  margin-bottom: 16px;
  color: #01285e;
  font-size: 15pt;
  background-color: #fff;
  margin-top: 15px;
  padding-left: 15px;
}
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 2.25rem;
}
.card-body > span {
  font-family: 'Gantari', sans-serif!important;
  margin: 0 0 10px;
  font-size: 17px;
  line-height: 1.42857143;
  font-weight: 300;
}
.pad {
  padding: 10px 10px;
}
.card {
  /* height: 550px; */
  min-height: 670px;
}
li {
  list-style-type: none;
}
.nav-link {
  color: black !important;
}
.nav-link:hover {
  color: #00b588 !important;
}
::v-deep span > p > .button {
  color: white !important;
}
::v-deep a.button.button-j {
  display: block;
  width: 315px;
  line-height: 35px;
  padding-bottom: 5px;
  height: 53px;
}
@media only screen and (max-width: 768px) {
  .mainContent {
    padding: 15px;
  }
}
@media only screen and (max-width: 530px) {
  ::v-deep .wp-block-image.size-large > img {
    width: 100%;
  }
}
</style>
