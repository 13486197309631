<template>
  <div class="youTube">
      <div class="ad">
        <div class="ad" style="text-align:center;">
          <script
            async
            src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
            type="application/javascript"
          ></script>
          <script type="application/javascript">
            window.googletag = window.googletag || { cmd: [] };
            googletag.cmd.push(function() {
              billboardsizes = googletag
                .sizeMapping()
                .addSize([980, 0], [728, 90])
                .addSize([0, 0], [320, 50])
                .build(); 
              googletag
                .defineSlot(
                  "/2835490/New_PGA_Top_Banner_Home",
                  [
                    [970, 250],
                    [320, 50],
                    [728, 90],
                  ],
                  "div-gpt-ad-1568947342181-0"
                )
                .defineSizeMapping(billboardsizes)
                .addService(googletag.pubads());
              googletag.pubads().enableSingleRequest();
              googletag.enableServices();
            });
          </script>

          <!-- /2835490/New_PGA_Top_Banner_Home -->
          <div id="div-gpt-ad-1568947342181-0">
            <script type="application/javascript">
              googletag.cmd.push(function() {
                googletag.display("div-gpt-ad-1568947342181-0");
              });
            </script>
          </div>
        </div>
      </div>
    <b-container>
      <h4 class="title">
        Latest News
      </h4>
      <YouTube :cat='latest' :config="config"/>
      <h4 class="title">
        PGA Tournament Highlights
      </h4>
      <YouTube :cat='highlights' :config="config"/>
      <h4 class="title">
        PGA TV Livestreams
      </h4>
      <YouTube :cat='live' :config="config"/>
    </b-container>
  </div>
</template>
<script>
import YouTube from "@/components/youTube.vue";
import axios from 'axios'
export default {
  name: "video",
  components: {
    YouTube,
  },
  props:['config'],
      data(){
        return{
            latest:[],
            highlights:[],
            live:[],
        }
    },
  mounted() {
    axios
      .get(
          process.env.VUE_APP_YOUTUBE_URL+process.env.VUE_APP_YOUTUBE_PLAYLIST_LATEST+"&key="+process.env.VUE_GOOGLE_API_KEY
      )
      .then((response) => {
        this.latest = response.data;
        return axios.get(
          process.env.VUE_APP_YOUTUBE_URL+process.env.VUE_APP_YOUTUBE_PLAYLIST_HIGHLIGHTS+"&key="+process.env.VUE_GOOGLE_API_KEY
        );
      })
      .then((response) => {
        this.highlights = response.data;
        return axios.get(
          process.env.VUE_APP_YOUTUBE_URL+process.env.VUE_APP_YOUTUBE_PLAYLIST_LIVE+"&key="+process.env.VUE_GOOGLE_API_KEY
        );
      })
      .then((response) => {
        this.live = response.data;
      });
  },
};
</script>

<style scoped>
.youTube {
  padding: 60px 100px;
}
.title {
  font-weight: 500;
  font-family: 'Gantari', sans-serif!important;
  color: #000;
  font-size: 23pt;
  margin-top: 1em;
  line-height: 1.1;
  letter-spacing: 2px;
}.ad {
  /* padding: 15px 0;  */
  margin-bottom: 3em;
}
@media only screen and (max-width: 768px) {
  .youTube{
    padding: 0;
  }
}
</style>
