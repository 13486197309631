<template>
  <div id="newsStory">
    <b-container class="content">
      <b-row>
        <b-col class="col-md-8 newsBackground">
          <h2 v-html='postInfo.title.rendered' class="article-heading">
          </h2>
          <b-img
            class="storyImg"
            :src="postInfo.better_featured_image.source_url"
            fluid
            alt="Responsive image"
          />
          <span v-html="postInfo.content.rendered" class="newsDescript"></span>
        </b-col>
        <b-col class="col-md-4">
          <b-row> 
            <b-col>
              <div class="ad rightad" style="padding-bottom: 2em;">
                <script type="application/javascript" async src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"></script>
                <script type="application/javascript">
                  window.googletag = window.googletag || {cmd: []};
                  googletag.cmd.push(function() {
                  billboardsizes = googletag.sizeMapping()
                  .addSize([980,690],[300,250])
                  .addSize([0,0],[320,50])
                  .build();
                  googletag.defineSlot('/2835490/New_PGA_Top_MREC', [[320, 50], [300, 250]], 'div-gpt-ad-1568076309317-0').defineSizeMapping(billboardsizes).addService(googletag.pubads());
                  googletag.pubads().enableSingleRequest();
                  googletag.enableServices();
                  });
                </script>

                <!-- /2835490/New_PGA_Top_MREC -->
                <div id='div-gpt-ad-1568076309317-0'>
                  <script type="application/javascript">
                  googletag.cmd.push(function() { googletag.display('div-gpt-ad-1568076309317-0'); });
                  </script>
                </div>
              </div>
            </b-col>
          </b-row>
          <!-- <h4 class="title">
            Recent Posts
          </h4> -->
          <b-row>
            <b-col
              class="col-md-12"
              style="padding-bottom: 1em;"
              v-for="(recent, index) in recent.slice(0, 5)"
              :key="index"
            >
              <b-nav-item :to="'/news/' + recent.id" class="box">
                <b-card
                  title="RECENT"
                  :sub-title="recent.title.rendered"
                  :img-src="recent.better_featured_image.source_url"
                  img-alt="Image"
                  img-top
                  tag="article"
                >
                </b-card>
              </b-nav-item>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
    <b-container>
      <h4 class="title">
        Related Content
      </h4>
      <b-row>
        <b-col v-for="(related, index) in related.slice(0, 4)" :key="index">
          <b-nav-item :to="'/news/' + related.id" class="box">
            <b-card
              title="NEWS"
              :sub-title="related.title.rendered"
              :img-src="related.better_featured_image.source_url"
              img-alt="Image"
              img-top
              tag="article"
            >
            </b-card>
          </b-nav-item>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "postFull",
  props: ["id"],
  data() {
    return {
      postInfo: [],
      recent: [],
      related: [],
    };
  },
  mounted() {
    axios
      .get(process.env.VUE_APP_WPAPI_URL+"wp/v2/posts/" + this.id)
      .then((response) => {
        this.postInfo = response.data;
        return axios.get(process.env.VUE_APP_WPAPI_URL+"wp/v2/posts/");
      })
      .then((response) => {
        this.recent = response.data;
        return axios.get(
          process.env.VUE_APP_WPAPI_URL+"wp/v2/posts?categories=" +
            this.postInfo.categories[0]
        );
      })
      .then((response) => {
        this.related = response.data;
      });
  },
};
</script>

<style scoped>
.newsBackground > h2 {
  color: #01285e;
  font-weight: 500;
  font-size: 2em;
}
.newsBackground {
  background-color: #fff;
  padding-top: 3.5em;
}
#newsStory {
  background-color: #f9f9f9;
  padding-bottom: 3em;
}
.content {
  padding: 50px 0;
}
.newsDescript {
  font-size: 12pt;
}
.storyImg {
  padding: 20px 0;
}
.box {
  display: block;
  top: 0px;
  border-radius: 4px;
  position: relative;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
}

.box:hover {
  transition: all 0.3s ease;
  box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
  top: -4px;
  background-color: white;
}
.card-title {
  font-weight: 700;
  letter-spacing: -0.5px;
  color: #9aa5ac;
  margin-top: -2px;
  margin-bottom: -5px;
  font-size: 8pt;
  color: #4e7650;
}
.card-subtitle {
  color: #01285e !important;
  font-weight: 400;
  font-size: 12pt;
  background-color: #fff;
  margin-top: 15px;
}
.nav-link {
  padding: 0;
}
.title {
  font-weight: 200;
  font-family: 'Gantari', sans-serif!important;
  color: #000;
  font-size: 23pt;
  margin-top: 1.1em;
  line-height: 1.1;
  letter-spacing: 2px;
}
</style>
