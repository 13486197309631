<template>
  <div>
    <!-- News Page -->
    <b-row v-if="featHome === 'false'" :class="postsRow">
      <b-col lg='6' cols='12'>
        <b-nav-item :to="'/news/' + allposts[0].id" class="box">
          <b-card
            :title="newLabel()"
            :sub-title="allposts[0].title.rendered"
            :img-src="allposts[0].better_featured_image.source_url"
            img-alt="Image"
            img-top
            tag="article"
            class="feat"
          >
            <b-card-text>
              <span v-html="allposts[0].excerpt.rendered"></span>
            </b-card-text>
          </b-card>
        </b-nav-item>
      </b-col>
      <b-col lg='6' cols='12'>
        <b-row>
          <b-col
            v-for="(featpost, index) in allposts.slice(1, 5)"
            :key="index"
            lg='6' cols='12'
            class="noRightPad bottomPad"
          >
            <b-nav-item :to="'/news/' + featpost.id" class="box">
              <b-card
                :title="newLabel()"
                :sub-title="featpost.title.rendered"
                :img-src="featpost.better_featured_image.source_url"
                img-alt="Image"
                img-top
                tag="article"
                class="featMob"
              >
            <b-card-text class='mobShow'>
              <span v-html="allposts[0].excerpt.rendered"></span>
            </b-card-text>
              </b-card>
            </b-nav-item>
          </b-col>
        </b-row>
      </b-col>
      <h4 class="viewAll">
        <a href="/#/pga-pro">View All</a>
        <font-awesome-icon class="icon" :icon="['fa', 'angle-right']" />
      </h4>
    </b-row>

    <!-- Home Page -->
    <b-row v-else-if="cat === '5' && featHome === 'true'" :class="postsRow">
      <b-col lg='8' md='12' cols='12'>
        <b-nav-item :to="'/news/' + allposts[0].id" class="box">
          <b-card
            title="INTERNATIONAL"
            :img-src="allposts[0].better_featured_image.source_url"
            img-alt="Image"
            img-top
            tag="article"
            class="feat"
          >
            <b-card-text>
              <h6 class="card-subtitleMain" v-html="allposts[0].title.rendered"></h6>
              <span class="excerptText" v-html="allposts[0].excerpt.rendered"></span>
            </b-card-text>
          </b-card>
        </b-nav-item>
      </b-col>
      <b-col lg='4' md='12' cols='12'>
        <b-row>
          <b-col cols='12' class="noRightPad bottomPad">
            <b-nav-item :to="'/news/' + allposts[1].id" class="box">
              <b-card
                title="INTERNATIONAL"
                :sub-title="allposts[1].title.rendered"
                :img-src="allposts[1].better_featured_image.source_url"
                img-alt="Image"
                img-top
                tag="article"
                class="featMob"
              >
              </b-card>
            </b-nav-item>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="noRightPad bottomPad">
            <b-nav-item :to="'/news/' + allposts[2].id" class="box">
              <b-card
                title="INTERNATIONAL"
                :sub-title="allposts[2].title.rendered"
                :img-src="allposts[2].better_featured_image.source_url"
                img-alt="Image"
                img-top
                tag="article"
                class="featMob"
              >
              </b-card>
            </b-nav-item>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row v-else-if="cat === '29'" :class="postsRow">
      <b-col lg='3' md='6' class='bottomPad'>
        <script
          type="application/javascript"
          async
          src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
        ></script>
        <script type="application/javascript">
          window.googletag = window.googletag || { cmd: [] };
          googletag.cmd.push(function() {
            billboardsizes = googletag
              .sizeMapping()
              .addSize([980, 690], [300, 250])
              .addSize([0, 0], [320, 50])
              .build();
            googletag
              .defineSlot(
                "/2835490/New_PGA_Top_MREC",
                [
                  [320, 50],
                  [300, 250],
                ],
                "div-gpt-ad-1568076309317-0"
              )
              .defineSizeMapping(billboardsizes)
              .addService(googletag.pubads());
            googletag.pubads().enableSingleRequest();
            googletag.enableServices();
          });
        </script>

        <!-- /2835490/New_PGA_Top_MREC -->
        <div id="div-gpt-ad-1568076309317-0" style="float: right;">
          <script type="application/javascript">
            googletag.cmd.push(function() {
              googletag.display("div-gpt-ad-1568076309317-0");
            });
          </script>
        </div>
      </b-col>
      <b-col
        v-for="(latNews, index) in allposts.slice(0, 3)"
        :key="index"
        lg='3' md='6'
        class='bottomPad'
      >
        <b-nav-item :to="'/news/' + latNews.id" class="box">
          <b-card
            title="LATEST NEWS"
            :sub-title="latNews.title.rendered"
            :img-src="latNews.better_featured_image.source_url"
            img-alt="Image"
            img-top
            tag="article"
          >
          </b-card>
        </b-nav-item>
      </b-col>
    </b-row>
    <b-row v-else-if="cat === '44'" :class="postsRow">
      <b-col
        v-for="(proNews, index) in allposts.slice(0, 8)"
        :key="index"
        lg='3' md='6' 
        class="bottomPad"
      >
        <b-nav-item :to="'/news/' + proNews.id" class="box">
          <b-card
            title="PGA PROFESSIONAL"
            :sub-title="proNews.title.rendered"
            :img-src="proNews.better_featured_image.source_url"
            img-alt="Image"
            img-top
            tag="article"
          >
          </b-card>
        </b-nav-item>
      </b-col>
      <h4 class="viewAll">
        <a href="/#/news">View All</a>
        <font-awesome-icon class="icon" :icon="['fa', 'angle-right']" />
      </h4>
    </b-row>
    <b-row v-else-if="cat === '8'" :class="postsRow">
      <b-col
        v-for="(proNews, index) in allposts.slice(0, 8)"
        :key="index"
        lg='3' md='6' 
        class="bottomPad"
      >
        <b-nav-item :to="'/news/' + proNews.id" class="box">
          <b-card
            title="LATEST NEWS"
            :sub-title="proNews.title.rendered"
            :img-src="proNews.better_featured_image.source_url"
            img-alt="Image"
            img-top
            tag="article"
          >
          </b-card>
        </b-nav-item>
      </b-col>
      <h4 class="viewAll">
        <a href="/#/news">View All</a>
        <font-awesome-icon class="icon" :icon="['fa', 'angle-right']" />
      </h4>
    </b-row>
    <!-- Media Centre -->
    <b-row v-else-if="cat === '14'" :class="postsRow">
      <b-col
        v-for="(proNews, index) in allposts.slice(0, 8)"
        :key="index"
        class="col-md-4 bottomPad"
      >
        <b-nav-item :to="'/news/' + proNews.id" class="box">
          <b-card
            title="Media Centre"
            :sub-title="proNews.title.rendered"
            :img-src="proNews.better_featured_image.source_url"
            img-alt="Image"
            img-top
            tag="article"
          >
          </b-card>
        </b-nav-item>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "interNews",
  props: ["cat", "featHome"],
  data() {
    return {
      allposts: [],
      postsRow: "posts",
      postImg: "postImg",
    };
  },
  methods: {
    newLabel: function(label) {
      if (this.cat === "8") label = "LATEST NEWS";
      else if (this.cat === "20") label = "AUS";
      else if (this.cat === "5") label = "INTERNATIONAL";
      else if (this.cat === "11") label = "adidas PGA Pro-Am Series";
      else if (this.cat === "32") label = "SParms PGA Legends Tour";
      else if (this.cat === "38") label = "THE 19TH";
      return label;
    },
  },
  mounted() {
    axios //add API Call
      .get(
        process.env.VUE_APP_WPAPI_URL+"wp/v2/posts?categories=" +
          this.cat +
          "&per_page=100"
      )
      .then((response) => (this.allposts = response.data));
  },
};
</script>

<style scoped>
::v-deep span.excerptText > p {
  font-size: 15px;
}
.card-text:last-child {
  margin-bottom: 0;
  color: #000;
}
.img-fluid {
  max-width: 100%;
  height: auto;
  width: 100%;
}
.card {
  height: 300.5px;
  padding-bottom: 5px;
}
.card.feat {
  height: 621px;
}
span.postText {
  font-size: 12pt;
}
h1.postsTitle {
  font-size: 20pt;
  padding-top: 15px;
  padding-bottom: 15px;
  white-space: nowrap;
  width: 350px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.overflowImage {
  overflow: hidden;
  height: 278px;
}

.card-title {
  font-weight: 700;
  letter-spacing: -0.5px;
  color: #9aa5ac;
  margin-top: -2px;
  margin-bottom: -5px;
  font-size: 8pt;
  color: #4e7650;
}
.feat > .card-body > .card-subtitle {
  font-size: 23pt !important;
}
.card-subtitle {
  color: #01285e !important;
  font-weight: 500;
  font-size: 13pt;
  background-color: #fff;
  margin-top: 15px;
}
.card-subtitleMain {
  color: #01285e !important;
  font-weight: 500;
  background-color: #fff;
  margin-top: 15px;
  font-size: 21pt !important;
  margin-bottom: 25px;
}
.viewAll {
  position: relative;
  top: 1em;
  color: #000;
  font-weight: 600 !important;
  font-size: 14px;
  font-family: 'Gantari', sans-serif!important;
  padding-left: 15px;
}
.viewAll > a {
  color: #000!important;
}
.noRightPad {
  padding-right: 0;
  padding-left: 5px;
}
.bottomPad {
  padding-bottom: 20px;
}
.nav-link {
  padding: 0;
}
.box {
  display: block;
  top: 0px;
  border-radius: 4px;
  position: relative;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
}

.box:hover {
  transition: all 0.3s ease;
  box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
  top: -4px;
  background-color: white;
}
.mobShow{
  display: none;
}
@media only screen and (max-width: 480px) {
  #mobilePost {
    padding-top: 2%;
    padding-bottom: 2%;
  }
  .feat{
    height: 485px!important;
  }
  .featMob{
    height:350px!important
  }
  .card{
    height: 320px;
  }
}
@media only screen and (max-width: 1165px) {
  #mobilePost:last-child {
    padding-right: 15px;
    margin-top: 2em;
  }
  div#mobilePost {
    margin-top: 1em;
  }
}

@media only screen and (max-width: 768px) {
.featMob{
  height: 621px;
}
.noRightPad{
  padding:15px;
}
.mobShow{
  display: block;
}
}
</style>
