<template>
  <div>
    <b-table :items="data" :fields="fields"> 
      <template #cell(WINNINGS)="row">
        {{ row.item.CURRENCY }} {{ row.item.WINNINGS }}
      </template>
      <template #cell(POSITION)="row">
        {{ row.item.POSITION }} <b-img class='trophy' :src="config.VUE_APP_WP_DIGITAL_OCEAN+'2019/07/TROPHY.png'"></b-img>
      </template>
      </b-table>
  </div>
</template>

<script>
export default {
  name: "playerresults",
  props: ['data', 'config'],
  data() {
    return {
      noEntry: "noEntry",
      fields: [
        { key: "DATE", label: "Date" },
        { key: "NAME", label: "Name" },
        { key: "POSITION", label: "Pos" },
        { key: "ROUNDS", label: "Rounds" },
        { key: "SCORE", label: "Score" },
        { key: "WINNINGS", label: "Winnings", class:"winningColors" }
      ]
    };
  },
};
</script>

<style scoped>
::v-deep td.winningColors {
  color: #003C52!important;
}
.noEntry {
  display: none;
}
::v-deep table>thead>tr>th{
  background-color: #00433E;
  padding: 16px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  border-right: 1px solid black;
}

::v-deep table>tbody>tr>td{
  text-align: center;
  padding: 5px;
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #f9f9f9!important;
  line-height: 22px;
  min-height: 38px;
  color: black;
  font-weight: 700;
  border-top: 1px solid #d9d9d8;
  font-size: 14px;
  white-space: nowrap;
  width: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.trophy{
  width: 15px;
}
</style>
