<template>
  <div>
    <b-table v-if="Array.isArray(stats)" :fields="fields" :items="stats">
      <template #cell(WINNINGS)="row">
        $ {{ row.item.WINNINGS }}
      </template>
      <template #cell(POSITION)="row">
        {{ row.item.POSITION }} <b-img class='trophy' :src="config.VUE_APP_WP_DIGITAL_OCEAN+'2019/07/TROPHY.png'"></b-img>
      </template>
    </b-table>
    <table v-else>
      <thead>
        <tr>
          <th class="title">
            Date
          </th>
          <th class="title">
            Name
          </th>
          <th class="title">
            Position
          </th>
          <th class="title">
            Score
          </th>
          <th class="title">
            VSPAR
          </th>
          <th class="title">
            Round
          </th>
          <th class="title">
            Winnings
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="cell">
            {{ stats.DATE }}
          </td>
          <td class="cell">
            {{ stats.NAME }}
          </td>
          <td class="cell">
            {{ stats.POSITION }}
          </td>
          <td class="cell">
            {{ stats.SCORE }}
          </td>
          <td class="cell">
            {{ stats.VSPAR }}
          </td>
          <td class="cell">
            {{ stats.ROUNDS }}
          </td>
          <td class="cell winnings">{{ stats.WINNINGS }} {{ stats.CURRENCY }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "playerCareerHigh",
  props: ["stats", 'config'],
  data() {
    return {
      fields: [
        { key: "DATE", label: "Date" },
        { key: "NAME", label: "Name" },
        { key: "POSITION", label: "Pos" },
        { key: "SCORE", label: "Score" },
        { key: "VSPAR", label: "VSPAR" },
        { key: "ROUNDS", label: "Round" },
        { key: "WINNINGS", label: "Winnings", class:"winningColors" }
      ]
    };
  }
};
</script>

<style scoped>
::v-deep td.winningColors {
  color: #003C52!important;
}
::v-deep table>thead>tr>th{
  background-color: #00433E;
  padding: 16px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  border-right: 1px solid black;
}

::v-deep table>tbody>tr>td{
  text-align: center;
  padding: 5px;
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #f9f9f9!important;
  line-height: 22px;
  min-height: 38px;
  color: black;
  font-weight: 700;
  border-top: 1px solid #d9d9d8;
  font-size: 14px;
  white-space: nowrap;
  width: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.trophy{
  width: 15px;
}
</style>
