<template>
  <div>
    <div class="tRowTop">
      <b-container>
        <MiniMenu :config="config" />
      </b-container>
    </div>
    <b-container>
      <div class="sections">
        <h4 class="title">
          SCHEDULES
        </h4>
        <b-row>
          <b-col lg="2" md="12" class="mobButton schedTour-btn">
            <div class="box shedBox">
              <b-button
                class="schImg aus"
                title="aus"
                v-on:click="changeTour"
              ></b-button>
            </div>
          </b-col>
          <b-col lg="2" md="12" class="mobButton schedTour-btn">
            <div class="box shedBox">
              <b-button
                class="schImg wpga"
                title="wpga"
                v-on:click="changeTour"
              ></b-button>
            </div>
          </b-col>
          <b-col lg="2" md="12" class="mobButton schedTour-btn">
            <div class="box shedBox">
              <b-button
                class="schImg snr"
                title="snr"
                v-on:click="changeTour"
              ></b-button>
            </div>
          </b-col>
          <b-col lg="2" md="12" class="mobButton schedTour-btn">
            <div class="box shedBox">
              <b-button
                class="schImg all"
                title="all"
                v-on:click="changeTour"
              ></b-button>
            </div>
          </b-col>
          <b-col lg="2" md="12" class="mobButton schedTour-btn">
            <div class="box shedBox">
              <b-button
                class="schImg trn"
                title="trn"
                v-on:click="changeTour"
              ></b-button>
            </div>
          </b-col>
          <b-col lg="2" md="12" class="mobButton schedTour-btn">
            <div class="box shedBox">
              <b-button
                class="schImg ppc"
                title="ppc"
                v-on:click="changeTour"
              ></b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="sections">
        <h4 class="title">
          UPCOMING TOURNAMENTS
        </h4>
        <UpComingTours
          :futureTour="futureTours()"
          :tour="tour"
          :config="config"
        />
      </div>
      <div class="groupDropdown">
        <b-row>
          <b-col>
            <p class="filterTitle">Filter by Year</p>
            <b-nav class="dropdownSched">
              <!-- <b-dropdown :text="this.id + ' Season'">
                <b-dropdown-item
                  class="textPos"
                  v-on:click="changeYear"
                  v-for="(seasons,
                  index) in data.seasons.seasons_entry.slice().reverse()"
                  :key="index"
                  :title="seasons.code"
                  :year="seasons.code"
                  >{{ seasons.desc }}</b-dropdown-item
                >
              </b-dropdown> -->
              <b-form-select v-model="year" class="textPos" v-on:change="changeYear(year)">
                <b-form-select-option 
                  class="opt-arrow"
                  v-for="(value,
                  index) in data.seasons.seasons_entry.slice().reverse()"
                  :key="index"
                  :value="value.code"
                >{{value.desc}}</b-form-select-option>
              </b-form-select>
            </b-nav>
          </b-col>
          <b-col>
            <p class="filterTitle">Filter by State / Country</p>
            <b-nav class="dropdownSched">
              <b-dropdown :text="this.pickedState">
                <b-dropdown-item
                  class="textPos"
                  v-on:click="changeState"
                  v-for="(state, index) in states"
                  :key="index"
                  :title="state.state"
                  >{{ state.state }}</b-dropdown-item
                >
              </b-dropdown>
            </b-nav>
          </b-col>
          <b-col>
            <p class="filterTitle">Filter by Month</p>
            <b-nav class="dropdownSched">
              <b-dropdown :text="this.pickedMonth">
                <b-dropdown-item
                  class="textPos"
                  v-on:click="changeMonth"
                  v-for="(months, index) in month"
                  :key="index"
                  :title="months.month"
                  :num="months.num"
                  >{{ months.month }}</b-dropdown-item
                >
              </b-dropdown>
            </b-nav>
          </b-col>
          <b-col>
            <p class="filterTitle">Find a Tournament</p>
            <input
              class="Findatourn"
              name="gsearch"
              placeholder="Find a Tournament...."
              id="filter-input"
              v-model="filter"
              type="search"
            />
          </b-col>
        </b-row>
      </div>
      <ScheduleTable
        :fields="fields"
        :id="id"
        :data="filterMonth"
        :tour="tour"
        :develop="develop"
        :filter="filter"
        :config="config"
      />
    </b-container>
  </div>
</template>

<script>
import MiniMenu from "@/components/miniMenu.vue";
import ScheduleTable from "@/components/scheduleTable.vue";
import UpComingTours from "@/components/upComingTours.vue";
import axios from "axios";
export default {
  name: "schedule",
  props: ["develop", "config"],
  components: {
    ScheduleTable,
    UpComingTours,
    MiniMenu,
  },
  data() {
    return {
      id: [],
      tm_params: [],
      data: [],
      filter: [],
      filterOn: [],
      tour: this.$route.query.tour,
      pickedState: "ALL",
      pickedMonth: "ALL",
      pickedNum: "0",
      states: [
        { state: "New Zealand" },
        { state: "NSW" },
        { state: "NT" },
        { state: "QLD" },
        { state: "VIC" },
        { state: "WA" },
        { state: "ACT" },
        { state: "TAS" },
        { state: "SA" },
        { state: "Other" },
        { state: "ALL" },
      ],
      month: [
        { month: "ALL", num: "0" },
        { month: "January", num: "01" },
        { month: "Febuary", num: "02" },
        { month: "March", num: "03" },
        { month: "April", num: "04" },
        { month: "May", num: "05" },
        { month: "June", num: "06" },
        { month: "July", num: "07" },
        { month: "August", num: "08" },
        { month: "September", num: "09" },
        { month: "October", num: "10" },
        { month: "November", num: "11" },
        { month: "December", num: "12" },
      ],
      fields: [
        { key: "sponsor_logo", label: "", class: "tableTh TableLogo" },
        { key: "dates", label: "Dates", class: "tableTh TableDates" },
        { key: "full_name", label: "Tournament", class: "tableTh tableTourn" },
        { key: "winner_name", label: "Defending Champion", class: "tableTh TableDefend" },
        { key: "total_prize_fund", label: "Prize Money", class: "tableTh" },
      ],
    };
  },
  computed: {
    filterState: function() {
      var state = this.pickedState;
      if (state === "ALL") return this.data.tournaments.tournaments_entry;
      else
        return this.data.tournaments.tournaments_entry.filter(
          (tournaments_entry) =>
            !tournaments_entry.course_country.indexOf(state)
        );
    },
    filterMonth: function() {
      var pickedNum = this.pickedNum;
      if (pickedNum === "0") return this.filterState;
      else
        return this.filterState.filter(
          (tournaments_entry) =>
            !tournaments_entry.start_date.substr(3, 4).indexOf(pickedNum)
        );
    },
  },
  methods: {
    futureTours() {
      var data = this.data.tournaments.tournaments_entry;
      return data
        .filter((upcomingTours) => upcomingTours.days_away.indexOf("-"))
        .slice(0, 3);
    },
    changeState: function(event) {
      var pickedState = event.target.getAttribute("title");
      return (
        (this.pickedState = pickedState),
        sessionStorage.setItem("pickedState", pickedState)
      );
    },
    changeMonth: function(event) {
      var pickedMonth = event.target.getAttribute("title");
      var pickedNum = event.target.getAttribute("num");
      return (
        (this.pickedMonth = pickedMonth),
        (this.pickedNum = pickedNum),
        sessionStorage.setItem("pickedNum", pickedNum),
        sessionStorage.setItem("pickedMonth", pickedMonth)
      );
    },
    // changeYear: function(event) {
    //   var id = event.target.getAttribute("title");
    //   return (
    //     (this.id = id),
    //     sessionStorage.setItem("id", id),
    //     axios
    //       .get(
    //         process.env.VUE_APP_TIC_BASE +
    //           this.tour +
    //           "/" +
    //           this.id +
    //           "/tmticx?field=Y&fields=tournaments.tournaments_entry.code&fields=tournaments.tournaments_entry.short_name&fields=tournaments.tournaments_entry.days_away&fields=tournaments.tournaments_entry.full_name&fields=tournaments.tournaments_entry.sponsor_logo&fields=tournaments.tournaments_entry.dates&fields=tournaments.tournaments_entry.previous_winners&fields=tournaments.tournaments_entry.winner_name&fields=tournaments.tournaments_entry.winner_ref&fields=tournaments.tournaments_entry.course&fields=tournaments.tournaments_entry.total_prize_fund&fields=tournaments.tournaments_entry.class_group&fields=tournaments.tournaments_entry.start_date&fields=tournaments.tournaments_entry.end_date&fields=tournaments.tournaments_entry.course_country&fields=tournaments.tournaments_entry.ts_final_result&fields=tournaments.tournaments_entry.class_group&fields=tm_params.season_files&fields=tm_params.season_code&fields=seasons.seasons_entry&fields=tm_params.tour_name&fields=tm_params.hp_class&fields=tm_params.tour_logo&fields=code&fields=ts_final_result&randomadd=" +
    //           this.id +
    //           "/tmticx?randomadd=" +
    //           new Date().getTime()
    //       )
    //       .then((response) => (this.data = response.data))
    //   );
    // },

    changeYear: function(year) {
      console.log('Changing Year')
      return (
        axios
          .get(
            process.env.VUE_APP_TIC_BASE +
              this.tour +
              "/" +
             year +
              "/tmticx?field=Y&fields=tournaments.tournaments_entry.code&fields=tournaments.tournaments_entry.short_name&fields=tournaments.tournaments_entry.days_away&fields=tournaments.tournaments_entry.full_name&fields=tournaments.tournaments_entry.sponsor_logo&fields=tournaments.tournaments_entry.dates&fields=tournaments.tournaments_entry.previous_winners&fields=tournaments.tournaments_entry.winner_name&fields=tournaments.tournaments_entry.winner_ref&fields=tournaments.tournaments_entry.course&fields=tournaments.tournaments_entry.total_prize_fund&fields=tournaments.tournaments_entry.class_group&fields=tournaments.tournaments_entry.start_date&fields=tournaments.tournaments_entry.end_date&fields=tournaments.tournaments_entry.course_country&fields=tournaments.tournaments_entry.ts_final_result&fields=tournaments.tournaments_entry.class_group&fields=tm_params.season_files&fields=tm_params.season_code&fields=seasons.seasons_entry&fields=tm_params.tour_name&fields=tm_params.hp_class&fields=tm_params.tour_logo&fields=code&fields=ts_final_result&randomadd=" +
              year +
              "/tmticx?randomadd=" +
              new Date().getTime()
          )
          .then(
            (response) =>
              (this.data = response.data)
          )
      );
    },

    changeTour: function(event) {
      var tour = event.target.getAttribute("title");
      return (
        (this.tour = tour),
        (this.$route.query.tour = tour),
        sessionStorage.setItem("tour", tour),
        axios //add API Call
          .get(
            process.env.VUE_APP_TIC_BASE +
              this.tour +
              "/tmticx?field=Y&fields=tm_params.season_code&randomadd=1619597417671" +
              new Date().getTime()
          ) // Page number changed on each page based on JSON page id you are calling
          .then((response) => {
            (this.tm_params = response.data),
              (this.id = response.data.tm_params.season_code);
            return axios.get(
              process.env.VUE_APP_TIC_BASE +
                this.tour +
                "/" +
                this.id +
                "/tmticx?field=Y&fields=tournaments.tournaments_entry.code&fields=tournaments.tournaments_entry.short_name&fields=tournaments.tournaments_entry.days_away&fields=tournaments.tournaments_entry.full_name&fields=tournaments.tournaments_entry.sponsor_logo&fields=tournaments.tournaments_entry.dates&fields=tournaments.tournaments_entry.previous_winners&fields=tournaments.tournaments_entry.winner_name&fields=tournaments.tournaments_entry.winner_ref&fields=tournaments.tournaments_entry.course&fields=tournaments.tournaments_entry.total_prize_fund&fields=tournaments.tournaments_entry.class_group&fields=tournaments.tournaments_entry.start_date&fields=tournaments.tournaments_entry.end_date&fields=tournaments.tournaments_entry.course_country&fields=tournaments.tournaments_entry.ts_final_result&fields=tournaments.tournaments_entry.class_group&fields=tm_params.season_files&fields=tm_params.season_code&fields=seasons.seasons_entry&fields=tm_params.tour_name&fields=tm_params.hp_class&fields=tm_params.tour_logo&fields=code&fields=ts_final_result&randomadd=" +
                new Date().getTime()
            );
          })
          .then((response) => {
            this.data = response.data;
          })
      );
    },
  },
  mounted() {
    const tour = sessionStorage.getItem("tour", "");
    if (tour === null) {
      this.tour = this.$route.query.tour;
    } else this.tour = tour;
    const id = sessionStorage.getItem("id", "");
    if (id === null) {
      this.id = this.$route.query.id;
    } else this.id = id;
    const pickedState = sessionStorage.getItem("pickedState", "");
    if (pickedState === null) {
      this.pickedState = "ALL";
    } else this.pickedState = pickedState;
    const pickedNum = sessionStorage.getItem("pickedNum", "");
    if (pickedNum === null) {
      this.pickedNum = "0";
    } else this.pickedNum = pickedNum;
    const pickedMonth = sessionStorage.getItem("pickedMonth", "");
    if (pickedMonth === null) {
      this.pickedMonth = "ALL";
    } else this.pickedMonth = pickedMonth;
    axios //add API Call
      .get(
        process.env.VUE_APP_TIC_BASE +
          this.$route.query.tour +
          "/tmticx?field=Y&fields=tm_params.season_code&randomadd=1619597417671" +
          new Date().getTime()
      ) // Page number changed on each page based on JSON page id you are calling
      .then((response) => {
        this.tm_params = response.data;
        return axios.get(
          process.env.VUE_APP_TIC_BASE +
            this.$route.query.tour +
            "/" +
            this.id +
            "/tmticx?field=Y&fields=tournaments.tournaments_entry.code&fields=tournaments.tournaments_entry.short_name&fields=tournaments.tournaments_entry.days_away&fields=tournaments.tournaments_entry.full_name&fields=tournaments.tournaments_entry.sponsor_logo&fields=tournaments.tournaments_entry.dates&fields=tournaments.tournaments_entry.previous_winners&fields=tournaments.tournaments_entry.winner_name&fields=tournaments.tournaments_entry.winner_ref&fields=tournaments.tournaments_entry.course&fields=tournaments.tournaments_entry.total_prize_fund&fields=tournaments.tournaments_entry.class_group&fields=tournaments.tournaments_entry.start_date&fields=tournaments.tournaments_entry.end_date&fields=tournaments.tournaments_entry.course_country&fields=tournaments.tournaments_entry.ts_final_result&fields=tournaments.tournaments_entry.class_group&fields=tm_params.season_files&fields=tm_params.season_code&fields=seasons.seasons_entry&fields=tm_params.tour_name&fields=tm_params.hp_class&fields=tm_params.tour_logo&fields=code&fields=ts_final_result&randomadd=" +
            this.id +
            "/tmticx?randomadd=" +
            new Date().getTime()
        );
      })
      .then((response) => {
        this.data = response.data;
      });
  },
};
</script>

<style scoped>
p.filterTitle {
  font-weight: bold;
  font-size: 17px;
}
.shedBox {
  padding-bottom: 0px!important;
}
::v-deep td.tableTh.TableDefend {
  width: 250px;
}
::v-deep td.tableTh.tableTourn {
  width: 400px;
}
::v-deep td.tableTh.TableDates {
  width: 280px;
}
::v-deep td.tableTh.TableLogo {
  width: 120px;
}
div#text-field-container {
  position: relative;
  top: 10px;
  display: block !important;
  line-height: 42px;
}
.Findatourn {
  width: 100%;
  line-height: 33px;
  background-color: white;
  border: 1px solid black;
  padding-left: 10px;
}
input.Findatourn::placeholder {
  color: #aaa !important;
  padding-left: 10px;
  display: block;
  line-height: 34px;
  margin-top: 15px;
  position: relative;
  top: 0px;
  font-size: 11pt;
}
.groupDropdown {
  margin-top: 1em;
  margin-bottom: 1em;
}
.schedTour-btn {
  flex: 0 0 19.666667%;
  max-width: 19.666667%;
}
li {
  list-style-type: none;
}
::v-deep .nav-link {
  color: black !important;
  width: 100%;
}
.title {
  font-weight: 500;
  font-family: 'Gantari', sans-serif!important;
  color: #000;
  font-size: 20px;
  margin-top: 1em;
  line-height: 1.1;
  letter-spacing: 2px;
}
.schImg {
  width: 100%;
  height: 73px;
  border-radius: 3px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.aus {
  background-image: url(https://assets.pga.org.au/pga-aus/2021/03/PGA-Website-Schedule-new-main-Tour-350x100px.jpeg);
}
.wpga {
  background-image: url(https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/08/WPGA-Website-MiniBanner-350x100px.jpg);
}
.snr {
  background-image: url(https://assets.pga.org.au/pga-aus/2021/08/PGA-Website-MiniBanner-350x100pxLT.jpg);
}
.all {
  background-image: url(https://assets.pga.org.au/pga-aus/2021/05/PGA-Website-MiniBanner-350x100px_Adidas.jpg);
}
.trn {
  background-image: url(https://assets.pga.org.au/pga-aus/2021/03/PGA-MAIN_Website-Schedule-Tour-350x100px.jpg);
}
.ppc {
  background-image: url(https://assets.pga.org.au/pga-aus/2021/03/PGA-PC_Website-Schedule-Tour-350x100px.jpg);
}
.sections {
  padding: 20px 0;
}

.box {
  z-index: 0;
  overflow: hidden;
  -webkit-box-shadow: 0 3px 4px 0 rgb(35 35 35 / 10%), 0 0 1px 0 rgb(10 31 68 / 8%);
  box-shadow: 0 3px 4px 0 rgb(35 35 35 / 10%), 0 0 1px 0 rgb(10 31 68 / 8%);
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition: opacity .2s ease-out,-webkit-box-shadow .3s ease-out,-webkit-transform .3s ease-out;
  transition: opacity .2s ease-out,-webkit-box-shadow .3s ease-out,-webkit-transform .3s ease-out;
  transition: box-shadow .3s ease-out,transform .3s ease-out,opacity .2s ease-out;
  transition: box-shadow .3s ease-out,transform .3s ease-out,opacity .2s ease-out,-webkit-box-shadow .3s ease-out,-webkit-transform .3s ease-out;
  border-top: 0px solid #fff;
  border-bottom: 0px solid #fff;
  background: #fff;
  margin-bottom: 17px;
  padding-bottom: 10px;
  margin-bottom: 20px!important;
  width: 100%;
}

.box:hover {
  transition: all 0.3s ease;
  box-shadow: 0px 4px 8px rgb(38 38 38 / 20%);
  top: -4px;
  background-color: white;
  box-shadow: 0 2px 2px rgb(45 45 45 / 5%), 0 4px 4px rgb(49 49 49 / 5%),
    0 8px 8px rgb(42 42 42 / 5%), 0 16px 16px rgb(32 32 32 / 5%),
    0 32px 32px rgb(49 49 49 / 5%), 0 64px 64px rgb(35 35 35 / 5%);
  -webkit-transform: translate3D(0, -4px, 0);
  transform: translate3D(0, -2px, 0);
}
.ad {
  padding: 15px 0;
}
.card-remove-border {
  border: none;
}
nav.card-header.dropdown-seasons {
  padding-left: 30px;
  background: none;
  border: none;
  border-top: 0px;
}
::v-deep button {
  color: black;
  background-color: white;
  border: 0px solid black;
  border-radius: 0;
  width: 289px;
  text-align: left;
  font-weight: bold;
}
::v-deep button::after {
  float: right;
  margin-top: 10px;
}
::v-deep button:hover {
  color: #263056;
  background-color: white;
}
::v-deep th {
  color: white;
  background-color: #263056;
}
::v-deep tr:nth-child(even) {
  background-color: #dfe1e6;
}
.tRow {
  border-bottom: 1px solid #000;
  padding: 5px 0;
  background: white;
}
::v-deep ul.nav.dropdownSched > div > button {
  line-height: 26px;
  height: 38px;
  border: 1px solid #000;
}
.titleMainTour {
  color: #000;
  margin: 0;
  font-size: 10pt;
  font-weight: 700;
  line-height: 2;
  position: relative;
  top: 3px;
}
li {
  list-style-type: none;
}
.menuLink {
  text-align: right;
}
.menuLink > a {
  padding: 4px 0 0 3em;
  text-transform: capitalize;
  font-size: 10pt;
  font-weight: 500;
}
.tourLogo {
  max-width: 80px !important;
  margin-top: -3px;
  padding-left: 0;
  margin-left: 15px;
  max-height: 34px !important;
  width: initial !important;
}
@media only screen and (max-width: 768px) {
  ::v-deep ul.nav.dropdownSched {
    width: 100%;
    max-width: 100%;
    flex: 100%;
    display: inline-grid;
  }
  ::v-deep .Findatourn {
    width: -webkit-fill-available;
    line-height: 33px;
    background-color: white;
    border: 1px solid black;
    padding-left: 10px;
  }
  .mobHide {
    display: none;
  }
  .mobButton {
    padding: 5px 0;
  }
  ::v-deep .schImg {
    height: 160px;
  }
  ::v-deep .mobButton {
    padding: 10px;
    width: 100%;
    max-width: 100%;
    flex: 100%;
  }
  ::v-deep .TableDefend {
    display: none;
  }
}
</style>
